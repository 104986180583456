#loja-basica {
  background-image: url('../../../../assets/img/loja-basica-bg.png'),
    linear-gradient(90deg, #f3b0bb 0%, #f6c7ce 100%);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 0 0 3rem;
}

#loja-basica .header-basica {
  background: url('../../../../assets/img/loja-basica-header.png')
    no-repeat;
  background-size: cover;
  height: 450px;
  background-position: bottom;
}

#loja-basica .header-basica h1 {
  font-size: 5rem;
  font-family: oduda-bold;
  color: #ffffff;
  margin: 0 0 3rem;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#loja-basica .aside-bar {
  background-color: #ffffff;
}

#loja-basica h3 {
  color: #ffffff;
  margin: 0;
  text-align: center;
  font-size: 2rem;
  font-family: oduda-bold;
}

#loja-basica .box-radius {
  background-color: #fad3da;
  padding: 3rem;
  border-radius: 40px;
}

#loja-basica .box-radius li {
  color: #703a12;
  font-size: 1.2rem;
  font-family: oduda;
  margin-bottom: 1rem;
}

#loja-basica .box-radius em {
  color: #703a12;
  font-size: 1rem;
  font-family: oduda;
  margin-bottom: 1rem;
}

#loja-basica .box-radius li b {
  font-family: oduda-bold;
}

@media screen and (max-width: 768px) {
  #loja-basica .header-basica h1 {
    font-size: 4rem;
  }

  #loja-basica h3{
    font-size: 1.5rem;
  }
}
