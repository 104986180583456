header {
  position: fixed;
  width: 100%;
  top: 10px;
  z-index: 999;

  .aside,
  .main {
    padding: 0;
    margin: 0;
  }

  .aside,
  nav {
    background-color: #ffffff;
    height: 40px;
  }

  .main,
  .content-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .content-logo {
    .before-logo,
    .after-logo {
      width: 40px;
      height: 40px;
      display: flex;
    }

    .before-logo {
      background: radial-gradient(
        circle at center right,
        transparent 35px,
        #ffffff 0px
      );
    }

    .after-logo {
      background: radial-gradient(
        circle at center left,
        transparent 35px,
        #ffffff 0px
      );
    }

    img{
      margin: 0 -30px;
    }
  }

  nav {
    display: flex;
    width: 100%;
    padding-left: 1rem;

    ul {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      li {
        list-style: none;
        display: flex;

        a {
          display: flex;
          align-items: center;
          padding: 0 1rem;
          text-decoration: none;
          transition: all 0.3s;

          &:hover {
            transform: scale(1.1, 1.1);
          }

          span {
            color: #074470;
          }
        }
      }
    }
  }
}
