.sobre-a-marca {
  display: flex;
  background-image: url('../../assets/img/sobre-a-marca-bg.png'),
    linear-gradient(
      130deg,
      rgba(236, 171, 174, 1) 0%,
      rgba(241, 147, 152, 1) 100%
    );
  background-size: contain;
  background-repeat: repeat;
  padding: 7rem 0 3rem;

  width: 100%;
  overflow: hidden;
}

.sobre-a-marca p {
  font-family: oduda-light;
  color: #a82d33;
  font-size: 1.2rem;
  text-align: center;
}

.sobre-a-marca b {
  font-family: oduda-bold;
  color: #710e13;
}

.sobre-a-marca .primeira-chq,
.sobre-a-marca .foto-isaias {
  width: 100%;
}

.sobre-a-marca .depoimento-isaias {
  display: flex;
  align-items: center;
}

.sobre-a-marca .depoimento-isaias > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sobre-a-marca .depoimento-isaias p {
  text-align: left;
}

.sobre-a-marca .depoimento-isaias p::before {
  content: open-quote;
  font-family: sans-serif;
  color: #710e13;
  font-size: 3em;
  vertical-align: bottom;
}

.sobre-a-marca .depoimento-isaias p + img {
  width: 50%;
  margin-right: 10%;
}

.sobre-a-marca .shakemix,
.sobre-a-marca .lojas {
  width: 100% !important;
  z-index: 999;
}

.sobre-a-marca .box-shakemix {
  z-index: 90;
}

.sobre-a-marca .box-shakemix:hover .shakemix {
  transform: rotate(180deg);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .sobre-a-marca {
    padding: 7rem 2rem 3rem;
  }

  .sobre-a-marca .foto-isaias {
    padding: 0 2rem;
  }

  .sobre-a-marca .shakemix2 {
    display: none;
  }
}
