.slick-slide img {
  display: flex;
  width: 100%;
  object-fit: cover;
}

.slick-dots {
  bottom: 20px;
}

@media screen and (max-width: 768px) {
  .slick-slide img {
    height: auto;
    /* height: 500px; */
  }
}
