li.menu-destaque a {
  background: #da2030;
  padding: 15px 25px;
  position: absolute;
  top: 17px;
  color: #fff;
  border-radius: 20px;
  border: 3px solid #fff;
}
li.menu-destaque span {
  color: #fff;
  font-size: 1.1em;
}

footer {
  background-color: #e7f0f6;
  padding: 0.5rem 0;
}

footer h1 {
  color: #0072bc;
  font-size: 1.8rem;
  font-family: oduda-bold;
  margin: 0 0 0.5rem;
}

footer h3 {
  color: #0072bc;
  font-family: oduda-bold;
  margin: 0 0 0.5rem;
}

footer span,
footer a,
footer .tel,
footer .email {
  font-family: oduda-light;
  color: #788790;
  text-decoration: none;
  margin-bottom: 0.2rem;
}

footer img + span {
  color: #437394;
  font-family: oduda;
}

footer img + a {
  color: #0072bc;
  font-family: oduda;
  margin-left: 0.5rem;
}

footer a,
footer .logo-chiquinho {
  transition: all 0.3s;
}

footer a:hover,
footer .logo-chiquinho:hover {
  transform: scale(1.05);
}

footer .tel,
footer .email {
  margin: 0;
}

footer .tel:hover,
footer .email:hover {
  transform: scale(1);
}

@media screen and (max-width: 768px) {
  footer {
    padding: 2rem;
  }

  footer .centralizado {
    justify-content: center;
  }
}
