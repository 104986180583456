#modelos-externos {
  background-image: url('../../../../assets/img/modelos-externos-bg.png'),
    linear-gradient(90deg, #024b80 0%, #0f68a8 100%);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 0 0 3rem;
}

#modelos-externos .header-modelos-externos {
  background: url('../../../../assets/img/modelos-externos-header.jpg')
    no-repeat;
  background-size: cover;
  height: 450px;
  background-position: bottom;
}

#modelos-externos .header-modelos-externos h1 {
  font-size: 5rem;
  font-family: oduda-bold;
  color: #ffffff;
  margin: 0 0 3rem;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#modelos-externos .aside-bar {
  background-color: #ffffff;
}

#modelos-externos h3 {
  color: #ffffff;
  margin: 0;
  text-align: center;
  font-size: 2rem;
  font-family: oduda-bold;
}

#modelos-externos h4 {
  color: #ffffff;
  margin: 0;
  text-align: left;
  font-size: 1.7rem;
  font-family: oduda-bold;
}

#modelos-externos .box-radius {
  background-color: #0f68a8;
  padding: 3rem;
  border-radius: 40px;
}

#modelos-externos .box-radius li {
  color: #96d0f6;
  font-size: 1.2rem;
  font-family: oduda;
  margin-bottom: 1rem;
}

#modelos-externos .box-radius em {
  color: #96d0f6;
  font-size: 1rem;
  font-family: oduda;
  margin-bottom: 1rem;
}

#modelos-externos .box-radius li b {
  font-family: oduda-bold;
}

@media screen and (max-width: 768px) {
  #modelos-externos .header-basica h1 {
    font-size: 4rem;
  }

  #modelos-externos h3{
    font-size: 1.5rem;
  }
}
