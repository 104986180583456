.typeform-chiquinho {height:70vh !important; width: 100%;}
.typeform-chiquinho.tf-v1-widget-fullscreen {position: relative !important;}

#seja-um-franqueado {
  background-image: url('../../assets/img/seja-um-franqueado-bg.png'),
    linear-gradient(
      130deg,
      rgba(237, 177, 76, 1) 0%,
      rgba(255, 221, 150, 1) 100%
    );
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  padding: 7rem 0 0;

  width: 100%;
  overflow: hidden;

  h3 {
    font-size: 2rem;
    font-family: oduda-bold;
    color: #64270b;
  }

  p {
    color: #006bb7;
    font-family: oduda-bold;
    font-size: 1.2rem;
  }

  .form-franqueado-content {
    padding: 2rem;
    background-color: #f0a265;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-top: 0;
    }

    .p-field {
      margin-bottom: 0.5rem;
    }

    label {
      color: #a00e13;
      font-size: 1rem;
      font-family: oduda-bold;
      margin-left: 0.5rem;
      margin-bottom: 0.2rem;
    }

    .p-dropdown {
      background: #e87f54;
      border-color: #e87f54;
      color: #ffffff;
      border-radius: 20px;

      .p-dropdown-trigger {
        color: #ffffff;
      }
    }

    .p-inputtext {
      border-radius: 20px;
      background-color: #e87f54;
      border-color: #e87f54;
      font-size: 1rem;
      font-family: oduda-light;
      padding: 0.5rem;
      color: #ffffff;
    }

    .p-inputtext:enabled:focus,
    .p-button:focus,
    .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: none;
    }

    .p-button {
      background: #64270b;
      border-color: #64270b;
      border-radius: 20px;
      transition: all 0.3s;

      span {
        color: #f0a265;
      }
    }

    .p-button:hover {
      background: #e87f54;
      border-color: #e87f54;

      span {
        color: #64270b;
      }
    }
  }

  .anos-40 {
    margin: 4rem 0;

    img {
      width: 100%;
    }
  }

  .diferenciais-da-chiquinho {
    background-color: #5e114c;
    padding: 2rem 0;

    h3 {
      color: #ec44ce;
      font-family: oduda-bold;
      font-size: 3rem;
      margin-top: 0;
      text-align: center;
    }

    .diferenciais-img {
      display: flex;
      width: 100%;
      margin: -80px 0 20px;
    }

    .box-icon {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        transition: all 0.3s;
      }

      img:hover {
        transform: scale(1.1, 1.1);
      }

      span {
        color: #ffffff;
        font-family: oduda-bold;
        font-size: 1.2rem;
        text-align: center;
        margin-top: 0.5rem;
      }
    }
  }

  .processo-franqueado {
    background-color: #5e114c;
    padding: 2rem 0;

    h3 {
      color: #ec44ce;
      font-family: oduda-bold;
      font-size: 3rem;
      margin-top: 0;
      text-align: center;
    }

    .processo-img {
      display: flex;
      width: 100%;
      margin: -60px 0 20px;
    }

    .processo-img:hover {
      transform: scale(1, 1) !important;
    }

    .box-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    img {
      transition: all 0.3s;
    }

    img:hover {
      transform: scale(1.1, 1.1);
    }

    span {
      color: #ffffff;
      font-family: oduda-bold;
      font-size: 1.2rem;
      text-align: center;
      margin-top: 0.5rem;
    }
  }

  .modelos-de-negocio {
    h1 {
      font-family: oduda-bold;
      font-size: 4rem;
      color: #d12228;
      margin: 0;
    }

    .loja {
      background-size: cover;
      background-repeat: no-repeat;
      height: 400px;
      width: 400px;
      display: flex;
      transition: all 0.3s;
    }

    .modelo-loja:last-child {
      margin-bottom: 2rem;
    }

    .img-loja-tradicional {
      background-image: url('../../assets/img/seja-um-franqueado-loja-tradicional.png');
    }

    .img-loja-tradicional:hover {
      background-image: url('../../assets/img/seja-um-franqueado-loja-tradicional-hover.png');
    }

    .img-loja-shopping {
      background-image: url('../../assets/img/seja-um-franqueado-loja-shopping.png');
    }

    .img-loja-shopping:hover {
      background-image: url('../../assets/img/seja-um-franqueado-loja-shopping-hover.png');
    }

    .img-loja-basica {
      background-image: url('../../assets/img/seja-um-franqueado-loja-basica.png');
    }

    .img-loja-basica:hover {
      background-image: url('../../assets/img/seja-um-franqueado-loja-basica-hover.png');
    }

    .img-loja-quiosque {
      background-image: url('../../assets/img/seja-um-franqueado-loja-quiosque.png');
    }

    .img-loja-quiosque:hover {
      background-image: url('../../assets/img/seja-um-franqueado-loja-quiosque-hover.png');
    }

    .img-modelos-externos {
      background-image: url('../../assets/img/seja-um-franqueado-modelos-externos.png');
    }
    

    .img-modelos-externos:hover {
      background-image: url('../../assets/img/seja-um-franqueado-modelos-externos-hover.png');
    }
  }

  *[readonly] {
    cursor: no-drop;
  }
}

.p-dialog {
  .p-dialog-footer {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #f3c3c5;
    border-color: #f3c3c5;

    .p-button {
      background: #64270b;
      border-color: #64270b;
      border-radius: 20px;
      transition: all 0.3s;

      span {
        color: #f0a265;
      }
    }
  }
}

.dialog-footer {
  .p-dialog-content {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

@media screen and (max-width: 992px) {

  #seja-um-franqueado .form-franqueado-content {
    padding: 1rem !important;
    height: 90vh !important;
  }

  #seja-um-franqueado .modelos-de-negocio {
    h1 {
      font-size: 3rem;
    }

    .loja {
      height: 350px;
      width: 350px;
    }
  }
}

@media screen and (max-width: 768px) {
  #seja-um-franqueado {
    h3 {
      text-align: center;
    }

    .modelo-loja {
      margin-bottom: 2rem;

      .loja {
        height: 300px;
        width: 300px;
      }

      .reverse {
        flex-direction: column-reverse;
      }
    }

    .modelos-de-negocio {
      padding-left: 2rem;
      padding-right: 2rem;

      p {
        text-align: center;
      }
    }

    p {
      margin-bottom: 0;
      text-align: center;
    }

    .diferenciais-da-chiquinho .diferenciais-img {
      margin: -60px 0 20px;
    }

    .processo-franqueado .processo-img {
      margin: -50px 0 20px;
    }
  }

  #seja-um-franqueado > div,
  #seja-um-franqueado .form-franqueado,
  #seja-um-franqueado .anos-40 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
