#cardapio {
  background-image: url('../../assets/img/cardapio-top-bg.png'),
    linear-gradient(
      130deg,
      rgba(209, 219, 230, 1) 0%,
      rgba(228, 232, 239, 1) 100%
    );
  background-repeat: no-repeat;
  background-position: top left;
  position: relative;
  /* padding: 7rem 0 3rem; */
}

#divProdutos img {
  margin-top: 3rem;
  width: 100%;
}

#cardapio h1 {
  font-size: 5rem;
  font-family: oduda-bold;
  color: #244070;
  margin: 0 0 2rem;
  text-align: center;
}

#cardapio .content-img {
  background: url('../../assets/img/cardapio-prod-bg.svg') no-repeat;
  background-size: contain;
  margin-bottom: 1rem;
  align-items: flex-start;
  height: auto;
  display: flex;
}

#cardapio .content-img img {
  width: 100%;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.5));
  transition: all 0.3s;
  height: auto;
  object-fit: contain;
  align-items: flex-start;
}

#cardapio .content-img img:hover {
  transform: scale(1.1, 1.1);
}

#cardapio span {
  font-size: 1.3rem;
  font-family: oduda-bold;
  color: #244070;
  text-align: center;
}

.download-nutrition-table {
  color: #394b6e;
  position: absolute;
  font-size: 1.1rem;
  top: 8.5%;
  right: 10px;
}

.download-nutrition-table a {
  color: #394b6e;
}

@media screen and (max-width: 1270px) {
  .download-nutrition-table {
    position: absolute;
    font-size: 0.8rem;
    width: 40%;
    top: 9.5%;
    left: 56%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 768px) {
  #cardapio {
    margin-top: -3rem;
  }

  #download-nutrition-table {
    height: 10rem;
  }
}

@media screen and (max-width: 767px) {
  .download-nutrition-table {
    position: absolute;
    font-size: 4px;
    width: 40%;
    top: 11.5%;
    left: 55%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}

@media screen and (max-width: 360px) {
  .download-nutrition-table {
    position: absolute;
    font-size: 4px;
    top: 12%;
    left: 58%;
    width: 40%;
    transform: translate(-50%, -50%);
  }
}
