.slick-slide img {
  display: flex;
  object-fit: cover;
  height: 100vh !important;
  width: 100%;
}

.slick-dots {
  bottom: 100px !important;
}

@media screen and (max-width: 768px) {
  .slick-slide img {
    height: auto;
  }
}
