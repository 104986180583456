#main-slider {
  width: 100%;
  height: 100vh;
}

.home-onde-tem {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: rgb(99, 150, 233);
  background: linear-gradient(
    130deg,
    rgba(99, 150, 233, 1) 0%,
    rgba(87, 150, 229, 1) 49%,
    rgba(134, 176, 247, 1) 100%
  );

  .arco-azul-content {
    background-image: url('../../assets/img/onde-tem-arco-bg.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 0em) calc(100% - 0.5em);
  }

  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95%;
    padding-left: 6rem;

    .main-top {
      width: 100%;
      height: 100%;
      align-items: flex-end;
      display: flex;

      .group-copo-onde-tem {
        position: relative;
        width: 100%;
        height: 250px;

        .desenho-copo {
          left: 20px;
          position: absolute;
        }

        .dash {
          position: absolute;
        }
        .dash1 {
          top: 135px;
          left: 10px;
        }
        .dash2 {
          top: 165px;
        }
        .dash3 {
          top: 200px;
          left: 5px;
        }
        .dash4 {
          top: 225px;
          left: 30px;
        }
        .dash5 {
          top: 220px;
          left: 65px;
        }
        .dash6 {
          top: 190px;
          left: 100px;
        }
        .dash7 {
          top: 160px;
          left: 110px;
        }
        .dash8 {
          top: 130px;
          left: 115px;
        }
        .dash9 {
          top: 110px;
          left: 135px;
        }
        .dash10 {
          top: 105px;
          left: 170px;
        }
        .dash11 {
          top: 110px;
          left: 210px;
        }
        .dash12 {
          top: 130px;
          left: 235px;
        }
        .dash13 {
          top: 165px;
          left: 240px;
        }
        .dash14 {
          top: 200px;
          left: 220px;
        }
        .dash15 {
          top: 225px;
          left: 190px;
        }
        .dash16 {
          top: 235px;
          left: 155px;
        }

        .dash17 {
          top: 130px;
          left: 185px;
        }
        .dash18 {
          top: 125px;
          left: 235px;
        }
        .dash19 {
          top: 135px;
          left: 290px;
        }
        .dash20 {
          top: 165px;
          left: 330px;
        }
        .dash21 {
          top: 215px;
          left: 335px;
        }
        .dash22 {
          top: 265px;
          left: 305px;
        }
        .dash23 {
          top: 295px;
          left: 260px;
        }
        .dash24 {
          top: 310px;
          left: 210px;
        }
        .dash25 {
          top: 295px;
          left: 260px;
        }
        .dash26 {
          top: 310px;
          left: 210px;
        }

        @keyframes intro {
          to {
            opacity: 1;
          }
        }

        .fade-in {
          opacity: 0;
          animation: intro 0.8s ease-in forwards;
        }
        .display-none {
          display: none;
        }
        .delay-0-5 {
          animation-delay: 0.5s;
        }
        .delay-0-6 {
          animation-delay: 0.6s;
        }
        .delay-0-7 {
          animation-delay: 0.7s;
        }
        .delay-0-8 {
          animation-delay: 0.8s;
        }
        .delay-0-9 {
          animation-delay: 0.9s;
        }
        .delay-1-0 {
          animation-delay: 1s;
        }
        .delay-1-1 {
          animation-delay: 1.1s;
        }
        .delay-1-2 {
          animation-delay: 1.2s;
        }
        .delay-1-3 {
          animation-delay: 1.3s;
        }
        .delay-1-4 {
          animation-delay: 1.4s;
        }
        .delay-1-5 {
          animation-delay: 1.5s;
        }
        .delay-1-6 {
          animation-delay: 1.6s;
        }
        .delay-1-7 {
          animation-delay: 1.7s;
        }
        .delay-1-8 {
          animation-delay: 1.8s;
        }
        .delay-1-9 {
          animation-delay: 1.9s;
        }
        .delay-2-0 {
          animation-delay: 2s;
        }
        .delay-2-1 {
          animation-delay: 2.1s;
        }
        .delay-2-2 {
          animation-delay: 2.2s;
        }
        .delay-2-3 {
          animation-delay: 2.3s;
        }
        .delay-2-4 {
          animation-delay: 2.4s;
        }
        .delay-2-5 {
          animation-delay: 2.5s;
        }
        .delay-2-6 {
          animation-delay: 2.6s;
        }
        .delay-2-7 {
          animation-delay: 2.7s;
        }
        .delay-2-8 {
          animation-delay: 2.8s;
        }
      }
    }

    .main-bottom {
      width: 100%;
      height: 50%;

      h3 {
        font-size: 3rem;
        font-family: oduda-bold;
        color: #de1717;
        margin: 0;
      }

      .p-dropdown {
        background: #5896e5;
        border: 2px solid #003a4f;
        border-radius: 20px;
        width: 100%;

        .p-dropdown-label.p-placeholder,
        .p-dropdown-trigger {
          color: #003a4f;
        }
      }
    }
  }

  .milkshake {
    height: 110vh;
    margin-top: 0vh;
    z-index: 777;
    transition: all 0.3s;
  }

  .milkshake:hover {
    transform: scale(1.1, 1.1);
  }

  .arcoAzul {
    position: absolute;
    height: 100%;
  }
}

.home-seja-um-franqueado {
  width: 100%;
  height: 100vh;
  background: rgb(237, 177, 76);
  /* url('../../assets/img/seja-um-franqueado-arco-loja.png'), */
  background-image: linear-gradient(
    130deg,
    rgba(237, 177, 76, 1) 0%,
    rgba(255, 212, 130, 1) 100%
  );
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  padding: 0 1rem;

  .arco {
    margin-left: -1rem;
    margin-top: 30vh;
    height: 110vh;
  }

  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

    .seja-um-franqueado-title {
      width: 100%;
      margin-top: -60px;
      z-index: 20;
    }

    b {
      color: #64270b;
      font-family: oduda-bold;
      font-size: 1.8rem !important;
      padding-right: 0.5rem;
    }

    .p-button-link {
      font-size: 3rem;
      color: #e02441 !important;
      text-decoration: none;
      font-family: oduda-bold;
      cursor: pointer;
      text-align: left;
      padding: 0 0.5rem 0.2rem;
      margin-bottom: 2rem;
      width: 330px;
      border: solid 4px #e02441 !important;
      border-radius: 10px;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
      line-height: 3rem;
    }

    .p-button-link:hover {
      transform: scale(1.05);
    }
  }

  .p-grid {
    margin-right: -1rem;
    margin-left: -1rem;

    .col-content {
      overflow: hidden;

      .loja {
        height: 100vh;
        margin-top: -0.5rem;
        margin-right: -0.5rem;
      }
    }
  }
}

.home-sobre-a-marca {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/sobre-a-marca-bg.png'),
    linear-gradient(
      130deg,
      rgba(236, 171, 174, 1) 0%,
      rgba(241, 147, 152, 1) 100%
    );
  display: flex;
  justify-content: center;

  .p-grid {
    height: 100%;
  }

  .box-img {
    align-items: center;
    display: flex;

    .casquinha {
      height: 500px;
      z-index: 100;
      margin-left: auto;
    }

    .logo-antigo {
      width: 180px;
      z-index: 200;
      margin-left: -260px;
      margin-top: 100px;
    }

    .logo-chiquinho {
      width: 200px;
      z-index: 300;
      margin-left: -40px;
      margin-top: 100px;
      cursor: default !important;
    }
  }

  h1 {
    font-size: 3rem;
    font-family: oduda-bold;
    color: #a82d33;
    margin: 0 0 2rem 0;
  }

  span {
    color: #a82d33;
    font-family: oduda-light;
    font-size: 1.5rem;
    display: block;

    b {
      font-family: oduda-bold;
    }
  }

  a {
    display: block;
    margin-top: 1rem;
    text-decoration: none;
    color: #a82d33;
    font-family: oduda-bold;
    font-size: 1.5rem;
  }
}

.p-dialog {
  border-radius: 20px;
  border-color: #f3c3c5;

  .p-dialog-header {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #f3c3c5;
    border-color: #f3c3c5;
    color: #64270b;

    .p-dialog-header-icon {
      color: #64270b;

      span {
        text-shadow: 1px 1px 1px #64270b;
      }
    }

    .p-dialog-title {
      font-size: 2rem !important;
      font-family: oduda-bold;
    }
  }

  .p-dialog-content {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #f3c3c5;
    border-color: #f3c3c5;
    padding-top: 0;

    .p-field {
      margin-bottom: 0.5rem;
    }

    h1,
    b {
      font-size: 1.3rem;
      color: #a00e13;
      font-family: oduda-bold;
    }
  }

  .card {
    padding: 2rem !important;

    b {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .home-seja-um-franqueado {
    .arco {
      height: auto !important;
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-onde-tem {
    padding: 2rem;
    min-height: 100vh;
    height: auto;
    background-image: url('../../assets/img/onde-tem-arco-bg.png'),
      linear-gradient(
        130deg,
        rgba(99, 150, 233, 1) 0%,
        rgba(87, 150, 229, 1) 49%,
        rgba(134, 176, 247, 1) 100%
      );
    background-repeat: no-repeat;
    background-position: right bottom;

    .main {
      padding-left: 0;
    }

    .content-milkshake {
      justify-content: center;

      .milkshake {
        height: 70vh;
      }
    }
  }

  .home-seja-um-franqueado {
    padding: 0;
    min-height: 100vh;
    height: auto;

    .p-grid {
      // margin-right: auto;
      flex-direction: column;
      margin: 0;
      display: flex;
      justify-content: space-between;
      height: 100vh;
    }

    .content-loja {
      display: none;
      padding: 0;
    }

    .main {
      align-items: center;
      justify-content: start;
      height: auto;
      padding: 0 2rem 0 2rem;
      margin: 0;

      .seja-um-franqueado-title {
        width: 250px;
        margin-top: -40px;
        margin-bottom: 2rem;
      }

      b {
        font-size: 1.3rem !important;
        text-align: center;
        margin-bottom: 2rem;
      }

      .p-button-link {
        font-size: 2.5rem;
        text-align: center;
        padding: 0 0.5rem 0.2rem;
        width: auto;
        line-height: 2.5rem;
      }
    }

    .arco-mobile{
      position: absolute;
      margin-top: 150px;
      height: 270px;
    }

    .loja {
      width: 100%;
    }
  }

  .home-sobre-a-marca {
    padding: 0 2rem;
    height: auto;
    // margin-top: -150px;

    .box-img {
      align-items: center;
      display: flex;

      .logo-antigo {
        position: absolute;
        margin-left: auto;
        width: 140px;
      }

      .logo-chiquinho {
        position: absolute;
        width: 160px;
        right: 4rem;
        margin-left: auto;
      }
    }

    h1 {
      font-size: 2rem;
    }

    h1,
    span,
    a {
      text-align: center;
    }
  }
}
