body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: oduda-bold;
  src: url('./assets/fonts/Oduda-Bold.otf');
}

@font-face {
  font-family: oduda;
  src: url('./assets/fonts/Oduda.otf');
}

@font-face {
  font-family: oduda-light;
  src: url('./assets/fonts/Oduda-Light.otf');
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
label {
  font-family: oduda;
}

ul {
  margin: 0;
  padding: 0;
}
