/* header.header-web {
  position: fixed;
  width: 100%;
  top: 10px;
  z-index: 999;
}

header.header-web .header-side {
  display: flex;
  height: 100%;
  align-items: center;
}

header.header-web .header-content-nav {
  display: flex;
  align-items: center;
}

header.header-web .header-bar {
  background-color: #ffffff;
  height: 40px;
  box-shadow: 0px 5px 5px #3333;
  width: 100%;
}

header.header-web i {
  background: url('../../assets/img/header-border-logo.png') no-repeat;
  background-size: contain;
  width: 7px;
  height: 40px;
  z-index: 999;
}

header.header-web i.i-after {
  width: 8px;
  margin-right: -1px;
}

header.header-web .p-col-fixed i:first-child {
  transform: rotate(180deg);
}

header.header-web nav {
  background-color: #ffffff;
  height: 40px;
  box-shadow: 0px 5px 5px #3333;
  width: 100%;
  display: flex;
  align-items: center;
}

header.header-web nav ul {
  display: flex;
  height: 100%;
  align-items: center;
}

header.header-web nav li {
  list-style: none;
  display: flex;
  height: 100%;
  align-items: center;
}

header.header-web nav li a {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1.3rem;
  transition: all 0.3s;
}

header.header-web nav li a:hover {
  transform: scale(1.1, 1.1);
}

header.header-web nav li span {
  color: #074470;
}

header.header-mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  header.header-web {
    display: none;
  }

  header.header-mobile {
    display: flex;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
  }
}

.btn-menu-mobile {
  margin: 20px 0px;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #de1717;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2)
}

.btn-menu-mobile:hover,
.btn-menu-mobile:focus{
  background-color: #ff3034 !important;
  border-color: #ff3034 !important;
  box-shadow: none;
}

.mobile-menu {
  flex-direction: column;
  display: flex;
  margin-top: 1rem;
}

.mobile-menu a:first-child{
  border-top: 1px solid #eeeeee;
}

.mobile-menu a {
  font-size: 1.2rem;
  color: #a82d33;
  font-family: oduda-light;
  text-decoration: none;
  padding: 1rem 0;
  border-bottom: 1px solid #eeeeee;
} */

header {
  .btn-menu-mobile {
    margin: 20px 0px;
    background-color: #ffffff;
    border-color: #ffffff;
    color: #de1717;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);

    &:hover,
    &:focus {
      background-color: #ff3034 !important;
      border-color: #ff3034 !important;
      box-shadow: none;
    }
  }
}

.mobile-menu {
  flex-direction: column;
  display: flex;
  margin-top: 1rem;

  a:first-child {
    border-top: 1px solid #eeeeee;
  }

  a {
    font-size: 1.2rem;
    color: #a82d33;
    font-family: oduda-light;
    text-decoration: none;
    padding: 1rem 0;
    border-bottom: 1px solid #eeeeee;
  }
}
