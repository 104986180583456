#loja-tradicional {
  background-image: url('../../../../assets/img/loja-tradicional-bg.png'),
    linear-gradient(90deg, #fec376 0%, #ffd892 100%);
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 0 0 3rem;
}

#loja-tradicional .header-tradicional {
  background: url('../../../../assets/img/loja-tradicional-header.png')
    no-repeat;
  background-size: cover;
  height: 450px;
  background-position: bottom;
}

#loja-tradicional .header-tradicional h1 {
  font-size: 5rem;
  font-family: oduda-bold;
  color: #ffffff;
  margin: 0 0 3rem;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#loja-tradicional .aside-bar {
  background-color: #ffffff;
}

#loja-tradicional h3 {
  color: #ffffff;
  margin: 0;
  text-align: center;
  font-size: 2rem;
  font-family: oduda-bold;
}

#loja-tradicional .box-radius {
  background-color: #f8dda8;
  padding: 3rem;
  border-radius: 40px;
}

#loja-tradicional .box-radius li {
  color: #703a12;
  font-size: 1.2rem;
  font-family: oduda;
  margin-bottom: 1rem;
}

#loja-tradicional .box-radius em {
  color: #703a12;
  font-size: 1rem;
  font-family: oduda;
  margin-bottom: 1rem;
}

#loja-tradicional .box-radius li b {
  font-family: oduda-bold;
}

@media screen and (max-width: 768px) {
  #loja-tradicional .header-tradicional h1 {
    font-size: 4rem;
  }

  #loja-tradicional h3 {
    font-size: 1.5rem;
  }
}
