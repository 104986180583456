section.categoria {
  position: relative;

  img {
    position: relative;
    display: flex;
    width: 100%;
    z-index: 1;
  }

  span.valor {
    z-index: 999;
    position: absolute;
    font-family: oduda-bold;
    font-size: 2.5vw;
    color: rgb(0, 0, 0);
  }

  span.indisponivel {
    font-family: oduda;
    background: #000;
    color: #fff;
    margin-right: -50px;
  }

  .font-size-1vw {
    font-size: 1vw;
  }
  .font-size-1-1vw {
    font-size: 1.1vw;
  }
  .font-size-1-2vw {
    font-size: 1.2vw;
  }
  .font-size-1-3vw {
    font-size: 1.3vw;
  }
  .font-size-1-4vw {
    font-size: 1.4vw;
  }
  .font-size-1-5vw {
    font-size: 1.5vw;
  }
  .font-size-1-7vw {
    font-size: 1.7vw;
  }
  .font-size-1-9vw {
    font-size: 1.9vw;
  }

  &.partePromo1 {
    span.val-1 {
      top: 20%;
      right: 60%;
      font-size: 1.5vw;
    }
    span.val-2 {
      top: 28.2%;
      right: 15.8%;
      font-size: 1.5vw;
    }
    span.val-3 {
      top: 37.45%;
      right: 60%;
      font-size: 1.5vw;
    }
    span.val-4 {
      top: 44.9%;
      right: 16%;
      font-size: 1.5vw;
    }
    span.val-5 {
      top: 49.1%;
      right: 14.3%;
      font-size: 1.5vw;
    }
    span.val-6 {
      top: 49.8%;
      right: 40.2%;
      font-size: 1.5vw;
    }
    span.val-7 {
      top: 55.2%;
      right: 60.3%;
      font-size: 1.5vw;
    }
    span.val-8 {
      top: 64.75%;
      right: 9.7%;
      font-size: 1.5vw;
    }
    span.val-9 {
      top: 74.1%;
      right: 60.3%;
      font-size: 1.5vw;
    }
    span.val-10 {
      top: 80.75%;
      right: 12.5%;
      font-size: 1.5vw;
    }
    span.val-11 {
      top: 91.25%;
      right: 59.8%;
      font-size: 1.5vw;
    }
    span.val-12 {
      top: 96.4%;
      right: 11.1%;
      font-size: 1.5vw;
    }
  }

   &.partePromo2 {
    span.val-1 {
      top: 47%;
      right: 59%;
      font-size: 4vw;
    }
    span.val-2 {
      top: 41.5%;
      right: 6%;
      font-size: 4vw;
    }
    span.val-3 {
      top: 68%;
      right: 46%;
      font-size: 2.5vw;
    }
    span.val-4 {
      top: 68%;
      right: 32.5%;
      font-size: 2.5vw;
    }
    span.val-5 {
      top: 79.2%;
      right: 55.5%;
      font-size: 2.5vw;
    }
    span.val-6 {
      top: 79.2%;
      right: 41.5%;
      font-size: 2.5vw;
    }
    span.val-7 {
      top: 89.7%;
      right: 46%;
      font-size: 2.5vw;
    }
    span.val-8 {
      top: 89.7%;
      right: 32.5%;
      font-size: 2.5vw;
    }
  }
   

  &.parte1 {
    span.val-1 {
      top: 8.7%;
      right: 53.5%;
      font-size: 1.5vw;
    }
    span.val-2 {
      top: 8.7%;
      right: 46.8%;
      font-size: 1.5vw;
    }
    span.val-3 {
      top: 8.7%;
      right: 40%;
      font-size: 1.5vw;
    }
    span.val-4 {
      top: 8.7%;
      right: 29%;
      font-size: 1.5vw;
    }
    span.val-5 {
      top: 8.7%;
      right: 22%;
      font-size: 1.5vw;
    }
    span.val-6 {
      top: 8.7%;
      right: 15.2%;
      font-size: 1.5vw;
    }
    span.val-7 {
      top: 28.6%;
      right: 27%;
      font-size: 1.5vw;
    }
    span.val-8 {
      top: 30.25%;
      right: 27%;
      font-size: 1.5vw;
    }
    span.val-9 {
      top: 28.6%;
      right: 7.5%;
      font-size: 1.5vw;
    }
    span.val-10 {
      top: 30.25%;
      right: 7.5%;
      font-size: 1.5vw;
    }
    span.val-11 {
      top: 39.5%;
      right: 23.5%;
      font-size: 1.7vw;
    }
    span.val-12 {
      top: 39.5%;
      right: 31%;
      font-size: 1.7vw;
    }
    span.val-13 {
      top: 52.1%;
      right: 29%;
      font-size: 1.7vw;
    }
    span.val-14 {
      top: 52.1%;
      right: 36.5%;
      font-size: 1.7vw;
    }
    span.val-15 {
      top: 62%;
      right: 36.5%;
      font-size: 1.7vw;
    }
    span.val-16 {
      top: 63.7%;
      right: 36.5%;
      font-size: 1.7vw;
    }
    span.val-17 {
      top: 62%;
      right: 19.3%;
      font-size: 1.7vw;
    }
    span.val-18 {
      top: 63.7%;
      right: 19.3%;
      font-size: 1.7vw;
    }
    span.val-19 {
      top: 74.2%;
      right: 12.6%;
      font-size: 1.5vw;
    }
    span.val-20 {
      top: 79.7%;
      right: 9%;
      font-size: 1.5vw;
    }
  }
  
  &.parte2 {
    span.val-1 {
      top: 2.9%;
      right: 11%;
      font-size: 2.3vw;
    }
    span.val-2 {
      top: 4.7%;
      right: 11%;
      font-size: 2.3vw;
    }
    span.val-3 {
      top: 5.8%;
      right: 11%;
      font-size: 2.3vw;
    }
    span.val-4 {
      top: 8.8%;
      right: 34%;
      font-size: 2.3vw;
    }
    span.val-5 {
      top: 8.8%;
      right: 18%;
      font-size: 2.3vw;
    }
    span.val-6 {
      top: 16.9%;
      right: 18.2%;
      font-size: 2.3vw;
    }
    span.val-7 {
      top: 23.1%;
      right: 14%;
      font-size: 2.3vw;
    }
    span.val-8a-1 {
      top: 36.9%;
      right: 12.5%;
      font-size: 2.5vw;
    }
    span.val-8b-1 {
      top: 38.15%;
      right: 12.5%;
      font-size: 2.5vw;
    }
    span.val-9 {
      top: 42.4%;
      right: 53%;
      font-size: 2.5vw;
    }
    span.val-10 {
      top: 42.4%;
      right: 35.5%;
      font-size: 2.5vw;
    }
    span.val-11 {
      top: 52.65%;
      right: 56.5%;
      font-size: 2.5vw;
    }
    span.val-12 {
      top: 52.65%;
      right: 44.1%;
      font-size: 2.5vw;
    }
     span.val-13 {
      top: 52.65%;
      right: 32.1%;
      font-size: 2.5vw;
    }
    span.val-14 {
      top: 60.4%;
      right: 22%;
      font-size: 2.5vw;
    }
    span.val-15 {
      top: 62%;
      right: 22%;
      font-size: 2.5vw;
    }
    span.val-16 {
      top: 65.8%;
      right: 30%;
      font-size: 2.5vw;
    }
    span.val-17 {
      top: 79.9%;
      right: 40%;
      font-size: 2.5vw;
    }
    span.val-18 {
      top: 85.2%;
      right: 40%;
      font-size: 2.5vw;
    }
    span.val-19 {
      top: 97.1%;
      right: 53%;
      font-size: 1.5vw;
    }
    span.val-20 {
      top: 98.05%;
      right: 52.8%;
      font-size: 1.5vw;
    }
      span.val-21 {
      top: 98.08%;
      right: 42.8%;
      font-size: 1.5vw;
    } 
      span.val-22 {
      top: 98.08%;
      right: 36%;
      font-size: 1.5vw;
    } 
      span.val-23 {
      top: 96.72%;
      right: 18.8%;
      font-size: 1.5vw;
    } 
      span.val-24 {
      top: 97.96%;
      right: 18.8%;
      font-size: 1.5vw;
    } 
      span.val-25 {
        top: 96.7%;
        right: 9.8%;
        font-size: 1.5vw;
    } 
      span.val-26 {
      top: 97.96%;
      right: 9.8%;
      font-size: 1.5vw;
    } 
  }

  &.parte3 {
    span.val-1 {
      top: 29%;
      right: 21.6%;
      font-size: 1.9vw;
    }
    span.val-2 {
      top: 44.4%;
      right: 31.8%;
      font-size: 1.9vw;
    }
    span.val-3 {
      top: 44.4%;
      right: 15.8%;
      font-size: 1.9vw;
    }
    span.val-4 {
      top: 90%;
      right: 61.3%;
      font-size: 1.1vw;
    }
    span.val-5 {
      top: 90%;
      right: 44.5%;
      font-size: 1.1vw;
    }
    span.val-6 {
      top: 90%;
      right: 27.9%;
      font-size: 1.1vw;
    }
    span.val-7 {
      top: 90%;
      right: 11.8%;
      font-size: 1.1vw;
    }
  }

  &.parte4 {
    span.val-1p, span.val-1g {
      top: 35.15%;
      right: 33%;
      font-size: 1.1vw;
    }
    span.val-2p, span.val-2g {
      top: 49.4%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-3p, span.val-3g {
      top: 52.7%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-4 {
      top: 56%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-5 {
      top: 59.3%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-6 {
      top: 62.6%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-7p, span.val-7g {
      top: 66%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-8p, span.val-8g {
      top: 69.3%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-9 {
      top: 72.6%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-10 {
      top: 76%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-11 {
      top: 79.3%;
      right: 21.3%;
      font-size: 1.1vw;
    }
    span.val-12p, span.val-12g {
      top: 49.4%;
      right: 5.5%;
      font-size: 1.1vw;
    }
    span.val-13p, span.val-13g {
      top: 52.7%;
      right: 5.5%;
      font-size: 1.1vw;
    }
    span.val-14p, span.val-14g {
      top: 56%;
      right: 5.5%;
      font-size: 1.1vw;
    }
    span.val-15p, span.val-15g {
      top: 59.3%;
      right: 5.5%;
      font-size: 1.1vw;
    }
    span.val-16p, span.val-16g {
      top: 62.6%;
      right: 5.5%;
      font-size: 1.1vw;
    }
    span.val-17p, span.val-17g {
      top: 66%;
      right: 5.5%;
      font-size: 1.1vw;
    }
    span.val-18p, span.val-18g {
      top: 69.3%;
      right: 5.5%;
      font-size: 1.1vw;
    }
    span.val-19 {
      top: 73%;
      right: 5.5%;
      font-size: 1.1vw;
    }
    span.val-20p, span.val-20g {
      top: 78%;
      right: 5.5%;
      font-size: 1.1vw;
    }
  }

  &.parte5 {
    span.val-1 {
      top: 16.1%;
      right: 8%;
      font-size: 1.5vw;
    }
    span.val-2 {
      top: 20.7%;
      right: 8%;
      font-size: 1.5vw;
    }
    span.val-3 {
      top: 23.8%;
      right: 8%;
      font-size: 1.5vw;
    }
    span.val-4 {
      top: 28%;
      right: 8%;
      font-size: 1.5vw;
    }
    span.val-5 {
      top: 32%;
      right: 8%;
      font-size: 1.5vw;
    }
    span.val-6 {
      top: 35.5%;
      right: 8%;
      font-size: 1.5vw;
    }
    span.val-7 { //Água sem gás
      top: 47.2%;
      right: 8%;
      font-size: 1.5vw;
    }
    span.val-8 { //Água com gás
      top: 50.5%;
      right: 8%;
      font-size: 1.3vw;
    }
    span.val-9 { //Refrigerante
      top: 53.4%;
      right: 8%;
      font-size: 1.3vw;
    }
    span.val-10 { //suco
      top: 56.5%;
      right: 8%;
      font-size: 1.3vw;
    }
    span.val-11 { //café shake - nutella
      top: 77.2%;
      right: 19%;
      font-size: 1.5vw;
    }
    span.val-12 { //café shake - tradicional
      top: 80.5%;
      right: 19%;
      font-size: 1.5vw;
    }
    span.val-13 { //café shake - doce de leite
      top: 84%;
      right: 19%;
      font-size: 1.5vw;
    }
    span.val-14 { //café shake - trufado
      top: 88.3%;
      right: 19%;
      font-size: 1.5vw;
    }
    span.val-15 { //café shake - pé de moleque
      top: 91.7%;
      right: 19%;
      font-size: 1.5vw;
    }
  }


  &.parte6 {
    span.val-1 {
      top: 26%;
      right: 45.8%;
      font-size: 1.1vw;
    }
    span.val-2 {
      top: 26%;
      right: 34.1%;
      font-size: 1.1vw;
    }
    span.val-3 {
      top: 26%;
      right: 23%;
      font-size: 1.1vw;
    }
    span.val-4 {
      top: 38.2%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-5 {
      top: 41%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-6 {
      top: 43.7%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-7 {
      top: 46.5%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-8 {
      top: 49%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-9 {
      top: 51.8%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-10 {
      top: 54.5%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-11 {
      top: 57.2%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-12 {
      top: 38.2%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-13 {
      top: 41%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-14 {
      top: 43.7%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-15 {
      top: 46.5%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-16 {
      top: 49%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-17 {
      top: 51.8%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-18 {
      top: 54.5%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-19 {
      top: 57.2%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-20 {
      top: 69.5%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-21 {
      top: 72.3%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-22 {
      top: 75%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-23 {
      top: 77.7%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-24 {
      top: 80.3%;
      right: 35.4%;
      font-size: 1.1vw;
    }
    span.val-25 {
      top: 69.5%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-26 {
      top: 72.2%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-27 {
      top: 75%;
      right: 10.3%;
      font-size: 1.1vw;
    }
    span.val-28 {
      top: 77.7%;
      right: 10.3%;
      font-size: 1.1vw;
    }
  }

  &.parte7 {    
    span.val-1 {
      top: 29.2%;
      right: 7.4%;
      font-size: 1.1vw;
    }
    span.val-2 {
      top: 62.6%;
      right: 10.9%;
      font-size: 1.1vw;
    }
  }

}
